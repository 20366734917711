<template>
  <div>
    <b-card>
      <validation-observer ref="form" slim>
          <h5>Personal Data</h5>
          <div class="row mt-2">
            <div class="col-lg-4">
              <validation-provider
                name="employee number"
                rules="required|min:6|max:10"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Employee Number *"
                  :invalid-feedback="errors[0]"
                >
                  <b-form-input
                    placeholder="Enter your employee number"
                    v-model="form.employeeNo"
                    @input="saveFormToLocal"
                    trim
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
              </validation-provider>
              <validation-provider
                name="full name"
                rules="required|min:1|max:255"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Fullname *"
                  :invalid-feedback="errors[0]"
                >
                  <b-form-input
                    placeholder="Enter your full name"
                    v-model="form.fullName"
                    @input="saveFormToLocal"
                    trim
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
              </validation-provider>
              <validation-provider
                name="nick name"
                rules="required|max:100"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Nickname *"
                  :invalid-feedback="errors[0]"
                >
                  <b-form-input
                    placeholder="Enter your nick name"
                    v-model="form.nickName"
                    trim
                    @input="saveFormToLocal"
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
              </validation-provider>
              <validation-provider
                name="identity card"
                rules="required|min:16|max:16"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Identity Card *"
                  :invalid-feedback="errors[0]"
                >
                  <b-form-input
                    placeholder="Enter your identity card number"
                    v-model="form.identityCard"
                    trim
                    @input="saveFormToLocal"
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
              </validation-provider>
            </div>
            <div class="col-lg-4">
              <validation-provider
                name="gender"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Gender *"
                  :invalid-feedback="errors[0]"
                >
                  <custom-select
                  v-model="form.gender"
                  label="text"
                  :options="genders"
                  :clearable="false"
                  placeholder="Select gender"
                  @input="saveFormToLocal"
                  :class="{ 'is-invalid': errors.length }"
                />
                </b-form-group>
              </validation-provider>
              <validation-provider
                  name="birth date"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group
                    label="Birth Date *"
                    :invalid-feedback="errors[0]"
                  >
                    <date-picker
                      placeholder="Select birth date"
                      class="mt-1"
                      type="date"
                      valueType="YYYY-MM-DD"
                      :value="formatDate(form.birthDate)"
                      format="DD-MM-YYYY"
                      :editable="false"
                      :clearable="false"
                      v-model="form.birthDate"
                      @input="saveFormToLocal"
                      :class="{ 'is-invalid': errors.length }">
                    </date-picker>
                  </b-form-group>
              </validation-provider>
              <validation-provider
                  name="birth place"
                  rules="required|max:50"
                  v-slot="{ errors }"
                >
                  <b-form-group
                    label="Birth Place *"
                    :invalid-feedback="errors[0]"
                  >
                    <b-form-input
                      placeholder="Enter your birth place"
                      class="mt-1"
                      v-model="form.birthPlace"
                      trim
                      @input="saveFormToLocal"
                      :class="{ 'is-invalid': errors.length }"
                    />
                </b-form-group>
              </validation-provider>
            </div>
            <div class="col-lg-4">
              <validation-provider
                name="religion"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Religion *"
                  :invalid-feedback="errors[0]"
                >
                  <custom-select
                    v-model="form.religionId"
                    label="name"
                    :options="religions"
                    :clearable="false"
                    placeholder="Select religion"
                    :reduce="({ id }) => id"
                    @input="saveFormToLocal"
                    :class="{ 'is-invalid': errors.length }"/>
                </b-form-group>
              </validation-provider>
              <validation-provider
                name="NPWP"
                rules="max:21"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="NPWP"
                  :invalid-feedback="errors[0]"
                >
                  <b-form-input
                    placeholder="Enter your npwp number"
                    v-model="form.npwp"
                    trim
                    @input="saveFormToLocal"
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
              </validation-provider>
              <validation-provider
                name="vaccine number"
                rules="min:21|max:21"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Vaccine Number"
                  :invalid-feedback="errors[0]"
                >
                  <b-form-input
                    placeholder="Enter your vaccine number"
                    v-model="form.vaccineNumber"
                    trim
                    @input="saveFormToLocal"
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
              </validation-provider>
            </div>
          </div>

          <hr>

          <h5>Employee Data</h5>
          <div class="row mt-2">
            <div class="col-lg-4">
              <validation-provider
                  name="join date"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group
                    label="Join Date *"
                    :invalid-feedback="errors[0]"
                  >
                    <date-picker
                      placeholder="Select join date"
                      type="date"
                      valueType="YYYY-MM-DD"
                      :value="formatDate(form.hireDate)"
                      format="DD-MM-YYYY"
                      :editable="false"
                      :clearable="false"
                      v-model="form.hireDate"
                      @input="saveFormToLocal"
                      :class="{ 'is-invalid': errors.length }">
                    </date-picker>
                  </b-form-group>
              </validation-provider>
              <validation-provider
                  name="resign date"
                  v-slot="{ errors }"
                >
                  <b-form-group
                    label="Resign Date"
                    :invalid-feedback="errors[0]"
                  >
                    <date-picker
                      placeholder="Select resign date"
                      type="date"
                      valueType="YYYY-MM-DD"
                      :value="formatDate(form.resignDate)"
                      format="DD-MM-YYYY"
                      :editable="false"
                      :clearable="false"
                      v-model="form.resignDate"
                      @input="saveFormToLocal"
                      :class="{ 'is-invalid': errors.length }">
                    </date-picker>
                  </b-form-group>
              </validation-provider>
              <validation-provider
                name="position"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Position *"
                  :invalid-feedback="errors[0]"
                >
                  <custom-select
                  v-model="form.position"
                  label="text"
                  :options="positions"
                  :clearable="false"
                  @input="saveFormToLocal"
                  placeholder="Select your position"
                  :class="{ 'is-invalid': errors.length }"
                />
                </b-form-group>
              </validation-provider>
            </div>
            <div class="col-lg-4">
              <validation-provider
                name="role"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Role *"
                  :invalid-feedback="errors[0]"
                >
                  <custom-select
                  v-model="form.role"
                  label="role"
                  :options="employeeRoles"
                  :clearable="false"
                  placeholder="Select your role"
                  @input="saveFormToLocal"
                  :class="{ 'is-invalid': errors.length }"
                />
                </b-form-group>
              </validation-provider>
              <validation-provider
                name="objective"
                rules="min:1|max:200"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Objective"
                  :invalid-feedback="errors[0]"
                >
                  <b-form-input
                    placeholder="Enter your objective"
                    v-model="form.objective"
                    trim
                    @input="saveFormToLocal"
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
              </validation-provider>
              <validation-provider
                name="profile"
                rules="min:1|max:200"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Profile"
                  :invalid-feedback="errors[0]"
                >
                  <b-form-input
                    placeholder="Enter your profile"
                    v-model="form.profile"
                    trim
                    @input="saveFormToLocal"
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
              </validation-provider>
            </div>
            <div class="col-lg-4">
              <validation-provider
                name="bank account number"
                rules="required|numeric|max:16"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Bank Account Number *"
                  :invalid-feedback="errors[0]"
                >
                  <b-form-input
                    placeholder="Enter your bank account number"
                    v-model="form.bankAccNumber"
                    type="text"
                    @input="() => {
                      restrictInputNumber($event)
                      saveFormToLocal()
                    }"
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
              </validation-provider>
              <validation-provider
                name="bank name"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Bank Name *"
                  :invalid-feedback="errors[0]"
                >
                  <custom-select
                  v-model="form.bankId"
                  label="name"
                  :options="bankNames"
                  :clearable="false"
                  :reduce="({id}) => id"
                  @input="saveFormToLocal"
                  placeholder="Select your bank name"
                  :class="{ 'is-invalid': errors.length }"
                />
                </b-form-group>
              </validation-provider>
              <validation-provider
                name="current salary"
                rules="required|numeric"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Current Salary"
                  description="Please input the nominal of current salary without punctuation. Example: 10000000"
                  :invalid-feedback="errors[0]"
                >
                  <b-form-input
                    placeholder="Enter your current salary"
                    v-model="form.currentSalary"
                    @input="() => {
                      restrictInputSalary($event)
                      saveFormToLocal()
                    }"
                    trim
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
              </validation-provider>
            </div>
          </div>

          <hr>

          <h5>Address</h5>
          <div class="row mt-2">
            <div class="col-lg-4">
              <validation-provider
                name="address"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Address *"
                  :invalid-feedback="errors[0]"
                >
                  <b-form-textarea
                    placeholder="Enter your address"
                    v-model="form.address"
                    trim
                    @input="saveFormToLocal"
                    rows="5"
                    :class="{ 'is-invalid': errors.length }"
                  ></b-form-textarea>
                </b-form-group>
              </validation-provider>
            </div>
            <div class="col-lg-4">
              <validation-provider
                name="province"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Province *"
                  :invalid-feedback="errors[0]"
                >
                  <custom-select
                    v-model="form.provinceId"
                    label="name"
                    :options="provinces"
                    :clearable="false"
                    placeholder="Select province"
                    @input="() => {
                      fetchCity(form.provinceId)
                      saveFormToLocal()
                    }"
                    :reduce="({ id }) => id"
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
              </validation-provider>
              <validation-provider
                name="city"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="City *"
                  :invalid-feedback="errors[0]"
                >
                  <custom-select
                  v-model="form.cityId"
                  label="name"
                  :options="cities"
                  :clearable="false"
                  placeholder="Select city"
                  @input="() => {
                    fetchDistrict(form.cityId)
                    saveFormToLocal()
                  }"
                  :class="{ 'is-invalid': errors.length }"
                />
                </b-form-group>
              </validation-provider>
            </div>
            <div class="col-lg-4">
              <validation-provider
                name="district"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="District *"
                  :invalid-feedback="errors[0]"
                >
                  <custom-select
                  v-model="form.districtId"
                  label="name"
                  :options="districts"
                  :clearable="false"
                  placeholder="Select district"
                  @input="saveFormToLocal()"
                  :class="{ 'is-invalid': errors.length }"
                />
                </b-form-group>
              </validation-provider>
              <validation-provider
                name="postal code"
                rules="required|numeric|min:5|max:5"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Postal Code *"
                  :invalid-feedback="errors[0]"
                >
                  <b-form-input
                    placeholder="Enter your postal code"
                    v-model="form.postalCode"
                    trim
                    @input="saveFormToLocal()"
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
              </validation-provider>
            </div>
          </div>

          <hr>

          <h5>Knowledge</h5>
          <div class="row mt-2">
            <div class="col-lg-4">
              <validation-provider
                name="language"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Language"
                  :invalid-feedback="errors[0]"
                >
                  <custom-select
                  v-model="form.language"
                  label="name"
                  :options="languages"
                  multiple
                  :clearable="false"
                  @input="saveFormToLocal"
                  placeholder="Select your language"
                  :class="{ 'is-invalid': errors.length }"
                />
                </b-form-group>
              </validation-provider>
              <validation-provider
                name="programming language"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Programming Language"
                  :invalid-feedback="errors[0]"
                >
                  <custom-select
                  v-model="form.knowledge.programmingLanguage"
                  label="name"
                  :options="programmingLanguages"
                  multiple
                  :clearable="false"
                  @input="saveFormToLocal"
                  placeholder="Select programming language"
                  :class="{ 'is-invalid': errors.length }"
                />
                </b-form-group>
              </validation-provider>
              <validation-provider
                name="operating system"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Operating System"
                  :invalid-feedback="errors[0]"
                >
                  <custom-select
                    v-model="form.knowledge.operatingSystem"
                    label="name"
                    multiple
                    :clearable="false"
                    :options="operatingSystems"
                    @input="saveFormToLocal"
                    placeholder="Select operating system"
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
              </validation-provider>
            </div>
            <div class="col-lg-4">
              <validation-provider
                name="framework"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Framework"
                  :invalid-feedback="errors[0]"
                >
                  <custom-select
                  v-model="form.knowledge.framework"
                  label="name"
                  :options="frameworks"
                  multiple
                  :clearable="false"
                  @input="saveFormToLocal"
                  placeholder="Select framework"
                  :class="{ 'is-invalid': errors.length }"
                />
                </b-form-group>
              </validation-provider>
              <validation-provider
                name="database"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Database"
                  :invalid-feedback="errors[0]"
                >
                  <custom-select
                  v-model="form.knowledge.database"
                  label="name"
                  :options="databases"
                  multiple
                  :clearable="false"
                  @input="saveFormToLocal()"
                  placeholder="Select database"
                  :class="{ 'is-invalid': errors.length }"
                />
                </b-form-group>
              </validation-provider>
              <validation-provider
                name="platform"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Platform"
                  :invalid-feedback="errors[0]"
                >
                  <custom-select
                  v-model="form.knowledge.platform"
                  label="name"
                  :options="platforms"
                  multiple
                  :clearable="false"
                  @input="saveFormToLocal"
                  placeholder="Select platform"
                  :class="{ 'is-invalid': errors.length }"
                />
                </b-form-group>
              </validation-provider>
            </div>
            <div class="col-lg-4">
              <validation-provider
                name="development tools"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Development Tools"
                  :invalid-feedback="errors[0]"
                >
                  <custom-select
                  v-model="form.knowledge.developmentTools"
                  label="name"
                  :options="developmentTools"
                  multiple
                  :clearable="false"
                  @input="saveFormToLocal"
                  placeholder="Select development tool"
                  :class="{ 'is-invalid': errors.length }"
                />
                </b-form-group>
              </validation-provider>
              <validation-provider
                name="others"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Others"
                  :invalid-feedback="errors[0]"
                >
                  <b-form-input
                    placeholder="Enter others"
                    v-model="form.other"
                    trim
                    @input="saveFormToLocal"
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
              </validation-provider>
            </div>
          </div>

          <hr>

          <div class="row">
            <div class="col-lg-4">
              <h5>Contact</h5>
              <validation-provider
                name="email address"
                rules="required|email|max:255"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Email Address *"
                  style="margin-top: 15px"
                  :invalid-feedback="errors[0]"
                >
                  <b-form-input
                    placeholder="Enter your email address"
                    type="email"
                    v-model="form.email"
                    @input="saveFormToLocal"
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
              </validation-provider>
              <validation-provider
                name="phone number"
                rules="required|numeric|min:10|max:13"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Phone Number *"
                  :invalid-feedback="errors[0]"
                >
                  <b-form-input
                    placeholder="Enter your phone number"
                    v-model="form.phoneNumber"
                    type="text"
                    @input="() => {
                      restrictInputNumber($event, 'phoneNumber')
                      saveFormToLocal()
                    }"
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
              </validation-provider>
              <validation-provider
                name="Emergency Contact Name"
                rules="required|max:25"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Emergency Contact Name *"
                  :invalid-feedback="errors[0]"
                >
                  <b-form-input
                    placeholder="Enter your emergency contact name"
                    v-model="form.emergencyContactName"
                    @input="saveFormToLocal()"
                    trim
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
              </validation-provider>
              <validation-provider
                name="Emergency Contact Number"
                rules="required|numeric|min:10|max:13"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Emergency Contact Number *"
                  :invalid-feedback="errors[0]"
                >
                  <b-form-input
                    placeholder="Enter your emergency contact number"
                    v-model="form.emergencyContactNumber"
                    type="text"
                    @input="() => {
                      restrictInputNumber($event, 'emergencyContactNumber')
                      saveFormToLocal()
                    }"
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
              </validation-provider>
            </div>
            <div class="col-lg-8">
              <div class="d-flex">
                <h5 class="mr-auto">Social Media</h5>
                <b-button
                  class="next merge justify-content-end ml-90"
                  @click="addFieldSocialMedia"
                  variant="primary"
                >
                  Add Social Media
                </b-button>
              </div>

              <div class="row">
                <div class="col-lg-6" v-if="form.socialMedia.length">
                  <div
                    v-for="(socialMedia, index) in form.socialMedia" :key="index"
                  >
                    <validation-provider
                      name="social media"
                      rules="max:100"
                      v-slot="{ errors }"
                    >
                      <b-form-group
                        :label="`Username Social Media ${index + 1}`"
                        description="ex: Username LinkendIn, Github, etc."
                        :invalid-feedback="errors[0]"
                      >
                        <b-form-input
                          placeholder="Enter social media"
                          v-model="form.socialMedia[index].username"
                          trim
                          @input="saveFormToLocal"
                          :class="{ 'is-invalid': errors.length }"
                        />
                      </b-form-group>
                    </validation-provider>
                  </div>
                </div>
                <div class="col-lg-6" v-if="form.socialMedia.length">
                  <div
                    v-for="(socialMedia, index) in form.socialMedia" :key="index"
                    class="d-flex justify-content-around"
                  >
                    <div class="row">
                      <div class="col">
                        <validation-provider
                          name="link social media"
                          rules="max:255"
                          v-slot="{ errors }"
                        >
                          <b-form-group
                            :label="`Link Social Media ${index + 1}`"
                            description="ex: https://www.linkedin.com/in/user-7212603b"
                            :invalid-feedback="errors[0]"
                          >
                            <b-form-input
                              placeholder="Enter link of your social media"
                              v-model="form.socialMedia[index].link"
                              trim
                              @input="saveFormToLocal"
                              :class="{ 'is-invalid': errors.length }"
                            />
                          </b-form-group>
                        </validation-provider>
                      </div>
                      <div class="col col-sm-3 col-lg-2 col-md-2">
                        <button
                          class="btn btn-danger"
                          style="height: 40px; margin-top: 27px"
                          @click="removeFieldSocialMedia(index)"
                        >
                          X
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </validation-observer>
          <div class="d-flex justify-content-end">
            <b-button
              class="next merge d-flex justify-content-end ml-90"
              style="mr-90rem;"
              type="submit"
              @click="saveNewEmployee"
              variant="primary">
              Save
            </b-button>
          </div>
    </b-card>
    <br>
  </div>
</template>

<script>
import api from '@/api'
import moment from 'moment'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default {
  components: { DatePicker },
  data() {
    return {
      form: JSON.parse(localStorage.getItem('employee_data')) ?? {
        employeeNo: '',
        fullName: '',
        nickName: '',
        identityCard: '',
        status: 'active',
        gender: '',
        birthPlace: '',
        npwp: '',
        birthDate: '2000-01-01',
        email: '',
        religionId: '',
        provinceId: '',
        cityId: '',
        districtId: '',
        phoneNumber: '',
        address: '',
        postalCode: '',
        vaccineNumber: '',
        objective: '',
        profile: '',
        other: '',
        emergencyContactName: '',
        emergencyContactNumber: '',
        socialMedia: [],
        language: [],
        knowledge: {},
        currentSalary: 0,
        hireDate: '',
        resignDate: null,
        position: '',
        bankAccNumber: '',
        bankId: '',
        role: ''
      },
      show: true,
      genders: ['Perempuan', 'Laki-laki'],
      religions: [],
      languages: [],
      provinces: [],
      cities: JSON.parse(localStorage.getItem('city_list')) ?? [],
      districts: JSON.parse(localStorage.getItem('district_list')) ?? [],
      frameworks: [],
      platforms: [],
      operatingSystems: [],
      databases: [],
      programmingLanguages: [],
      developmentTools: [],
      positions: ['Founder', 'Karyawan', 'Kontrak', 'Probation', 'KP/PKL'],
      employeeRoles: [],
      bankNames: []
    }
  },
  mounted() {
    this.fetchLanguage()
    this.fetchBank()
    this.fetchRole()
    this.fetchReligionEmployee()
    this.fetchProvince()
    this.fetchOperatingSystem()
    this.fetchFramework()
    this.fetchPlatform()
    this.fetchDatabase()
    this.fetchDevTools()
    this.fetchProgrammingLanguage()
  },
  methods: {
    formatDate(date) {
      var value = date === '' ? 'DD-MM-YYYY' : moment(date).format('YYYY-MM-DD')
      return value
    },
    restrictInputNumber(event, field) {
      if (/^\d*$/.test(event.target.value)) {
        return true
      } else {
        if (field === 'phoneNumber') {
          this.phoneNumber = this.phoneNumber.slice(0, -1)
        } else if (field === 'emergencyContactNumber') {
          this.emergencyContactNumber = this.emergencyContactNumber.slice(0, -1)
        } else {
          this.bankAccNumber = this.bankAccNumber.slice(0, -1)
        }
      }
    },
    restrictInputSalary(event) {
      if (/^\d*[.]?\d*$/.test(event.target.value)) {
        return true
      } else {
        this.currentSalary = this.currentSalary.slice(0, -1)
      }
    },
    async fetchLanguage() {
      const { data } = await api.employee.language()
      this.languages = data
    },
    async fetchBank() {
      const { data } = await api.employee.bank()
      this.bankNames = data
    },
    async fetchRole() {
      const { data } = await api.employee.role()
      this.employeeRoles = data.employeeRoles
    },
    async fetchReligionEmployee() {
      const { data } = await api.religions.list()
      this.religions = data
    },
    async fetchProvince() {
      const { data } = await api.address.province()
      this.provinces = data.data
      if (!JSON.parse(localStorage.getItem('city_list')).length) this.cities = []
      if (!JSON.parse(localStorage.getItem('district_list')).length) this.districts = []
    },
    async fetchCity(provinceId) {
      this.form.cityId = null
      this.form.districtId = null
      const { data } = await api.address.cityById(provinceId)
      this.cities = data.data
      this.districts = []
      this.saveFormToLocal()
    },
    async fetchDistrict(city) {
      this.form.districtId = null
      const { data } = await api.address.districtById(city.id)
      this.districts = data.data
      this.saveFormToLocal()
    },
    async fetchFramework() {
      const { data } = await api.knowledge.framework()
      this.frameworks = data
    },
    async fetchPlatform() {
      const { data } = await api.knowledge.platform()
      this.platforms = data
    },
    async fetchOperatingSystem() {
      const { data } = await api.knowledge.operatingSystem()
      this.operatingSystems = data
    },
    async fetchDatabase() {
      const { data } = await api.knowledge.database()
      this.databases = data
    },
    async fetchDevTools() {
      const { data } = await api.knowledge.devTools()
      this.developmentTools = data
    },
    async fetchProgrammingLanguage() {
      const { data } = await api.knowledge.programmingLanguage()
      this.programmingLanguages = data
    },
    async saveNewEmployee() {
      try {
        const valid = await this.$refs.form.validate()
        if (!valid) {
          this.isBusy = false
          this.$nextTick(() => {
            this.$bvToast.toast('Cannot save changes', {
              headerClass: 'd-none',
              solid: true,
              variant: 'danger'
            })
          })
          return
        }
        const newForm = Object.keys(this.form).reduce((item, key) => {
          if (key === 'birthDate') {
            item[key] = this.form.birthDate
            return item
          }

          if (key === 'cityId') {
            item[key] = this.form.cityId.id
            return item
          }

          if (key === 'districtId') {
            item[key] = this.form.districtId.id
            return item
          }

          item[key] = this.form[key]
          return item
        }, {})
        await api.employee.post(newForm)
        this.$nextTick(() => {
          setTimeout(() => {
            this.$bvToast.toast('Success save changes', {
              headerClass: 'd-none',
              solid: true,
              variant: 'success'
            })
          }, 500)
          this.$router.push('/manage-employees')
        })
      } catch (error) {
        this.isBusy = false
        this.$nextTick(() => {
          this.$bvToast.toast(error.message ? error.message.messageEng : error.data.messageEng, {
            headerClass: 'd-none',
            solid: true,
            variant: 'danger'
          })
        })
      }
    },
    saveFormToLocal() {
      localStorage.setItem('employee_data', JSON.stringify(this.form))
      localStorage.setItem('city_list', JSON.stringify(this.cities))
      localStorage.setItem('district_list', JSON.stringify(this.districts))
    },
    addFieldSocialMedia() {
      this.form.socialMedia.push({ username: '', link: '' })
      this.saveFormToLocal()
    },
    removeFieldSocialMedia(index) {
      this.form.socialMedia.splice(index, 1)
      this.saveFormToLocal()
    }
  },
  destroyed() {
    localStorage.removeItem('employee_data')
    localStorage.removeItem('city_list')
    localStorage.removeItem('district_list')
  }
}
</script>
